<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addCustomer']">新增客户</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="customerName" slot-scope="text, record">
          <div @click="$util.goHidden(authority.viewCustomerDetail, `/customer/customerDetail?id=${record.id}`, text)" class="text-underline">{{ text }}</div>
        </template>
        <template slot="customerLevel" slot-scope="text">
          <span v-if="text === 1">重要</span>
          <span v-if="text === 2">普通</span>
          <span v-if="text === 3">非优</span>
        </template>
        <template slot="privince" slot-scope="text, record">{{ text }} / {{ record.city }}</template>
        <template slot="id" slot-scope="text, record">
          <router-link :to="`/customer/customerDetail?id=${record.id}`" v-has-permission="['editCustomer']">
            <img src="@/assets/image/common/edit.png" alt="">
          </router-link>
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteCustomer']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm" class="drawer-form">
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="客户名称/联系电话" v-model="filterForm.name" @search="onFilter" />
          </a-form-model-item>
          <a-row style="margin-top: 20px">
            <a-form-model-item  label="客户级别">
              <a-select v-model="filterForm.customerLevel" placeholder="请选择客户级别">
                <a-select-option :value="1">重要</a-select-option>
                <a-select-option :value="2">普通</a-select-option>
                <a-select-option :value="3">非优</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="省/城市">
              <District :level="2" @change="districtChange" v-model="districtList"></District>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="新增客户"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="客户名称" prop="customerName">
              <a-input placeholder="请输入客户名称" v-model="form.customerName" />
            </a-form-model-item>
            <a-form-model-item  label="客户级别" prop="customerLevel">
              <a-select v-model="form.customerLevel" placeholder="请选择客户级别">
                <a-select-option :value="1">重要</a-select-option>
                <a-select-option :value="2">普通</a-select-option>
                <a-select-option :value="3">非优</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="联系电话" prop="phone">
              <a-input placeholder="请输入联系电话" v-model="form.phone" />
            </a-form-model-item>
            <a-form-model-item label="省/城市" prop="province">
              <District :level="2" @change="getDistrict" v-model="form.province"></District>
            </a-form-model-item>
            <a-form-model-item label="详细地址" prop="address">
              <a-textarea placeholder="请输入详细地址" v-model="form.address" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
            <a-form-model-item label="客户来源" prop="customerFrom">
              <a-textarea placeholder="请输入客户来源" v-model="form.customerFrom" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import District from '@/components/district/district'
import { createCustomer, getCustomerList, deleteCustomer } from '../../api/customer/customer'

export default {
  name: 'customerFile',
  components: {
    District
  },
  data () {
    return {
      columns: [
        {
          title: '客户名称',
          dataIndex: 'customerName',
          scopedSlots: { customRender: 'customerName' }
        },
        {
          title: '客户级别',
          dataIndex: 'customerLevel',
          scopedSlots: { customRender: 'customerLevel' }
        },
        {
          title: '合作次数',
          dataIndex: 'cooperationNum'
        },
        {
          title: '联系电话',
          dataIndex: 'phone'
        },
        {
          title: '省/城市',
          dataIndex: 'privince',
          scopedSlots: { customRender: 'privince' }
        },
        {
          title: '详细地址',
          dataIndex: 'address'
        },
        {
          title: '客户来源',
          dataIndex: 'customerFrom'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      staffShow: false,
      customerShow: false,
      rules: {
        customerName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' }
        ],
        customerLevel: [
          { required: true, message: '请选择客户级别', trigger: 'change' }
        ],
        province: [
          { required: true, message: '请选择省/城市', trigger: 'change' }
        ]
      },
      districtList: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getCustomerList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = { ...item }
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteCustomer({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createCustomer(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1,
        pageSize: 10
      }
      this.districtList = []
      this.getList()
    },
    districtChange (data) {
      this.filterForm.privince = data[0]
      this.filterForm.city = data[1]
    },
    getDistrict (data) {
      this.form.privince = data[0]
      this.form.city = data[1]
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
